var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CSDialog',{attrs:{"dialog-visible":_vm.dialogVisible,"dialog-title":"设置巡检人","dialog-width":"522px"},on:{"onConfirm":_vm.saveInspector,"onClose":_vm.dialogClose},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('div',{staticClass:"ibox-content",staticStyle:{"padding":"20px 30px"}},[_c('div',[_c('div',{staticClass:"form-content"},[(_vm.inspectScheduleType == 0)?[_c('div',{staticClass:"form-group row"},[_c('span',{staticClass:"notes"},[_vm._v("注：按添加顺序每日轮班巡检。（只添加一个，每日 巡检都由此人执行）")]),_c('label',{staticClass:"staff-label"},[_vm._v(" 选择员工 ")]),_c('div',{staticClass:"col-sm-9"},[_vm._l((_vm.everyDayInspector),function(inspector,index){return _c('div',{key:index,staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-10"},[_c('CSSelect',{staticStyle:{"width":"100%"},attrs:{"height":"40px","i-width":"36px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.checkedStaffIdList[
                                          index
                                      ]
                                  ),expression:"\n                                      checkedStaffIdList[\n                                          index\n                                      ]\n                                  "}],staticStyle:{"padding-left":"10px"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.checkedStaffIdList, 
                                          index
                                      , $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v(" 请选择 ")]),_vm._l((_vm.staffOptionsList),function(staff){return _c('option',{key:staff.id,domProps:{"value":staff.id}},[_vm._v(" "+_vm._s(staff.name)+" ")])})],2)])],1),(index > 0)?_c('div',{staticClass:"col-sm-2"},[_c('svg',{staticClass:"icon",staticStyle:{"line-height":"36px","position":"absolute","top":"7px"},attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.removeEveryDayInspectorItem(index)}}},[_c('use',{attrs:{"xlink:href":"#icon-menushanchu"}})])]):_vm._e()])}),_c('div',[_c('button',{staticClass:"btn btn-primary",staticStyle:{"border-radius":"6px","width":"80px","height":"40px","line-height":"20px","margin-left":"15px"},on:{"click":_vm.addEveryDayInspectorItem}},[_vm._v(" 添加 ")])])],2)])]:_vm._l((_vm.inspectScheduleData),function(inspectDate,index){return _c('div',{key:index,staticClass:"margin-bottom"},[(_vm.inspectScheduleType == 1)?[_c('label',{staticStyle:{"vertical-align":"middle","margin-right":"40px","margin-bottom":"0"}},[_vm._v(_vm._s(_vm.weekMapping[inspectDate]))])]:[_c('label',{staticStyle:{"vertical-align":"middle","margin-right":"40px","margin-bottom":"0"}},[_vm._v(_vm._s(inspectDate))])],_c('div',{staticStyle:{"display":"inline-block"}},[_c('CSSelect',{staticStyle:{"width":"300px","padding-left":"6px"},attrs:{"height":"40px","i-width":"36px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.checkedStaffIdMapping[
                                    inspectDate
                                ]
                            ),expression:"\n                                checkedStaffIdMapping[\n                                    inspectDate\n                                ]\n                            "}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.checkedStaffIdMapping, 
                                    inspectDate
                                , $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v(" 请选择 ")]),_vm._l((_vm.staffOptionsList),function(staff){return _c('option',{key:staff.id,domProps:{"value":staff.id}},[_vm._v(" "+_vm._s(staff.name)+" ")])})],2)])],1)],2)})],2)])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }