
<template>
    <div>
      <CSDialog :dialog-visible="dialogVisible" dialog-title="设置巡检人"
        @onConfirm="saveInspector" @onClose="dialogClose" dialog-width="522px"
      >
        <template v-slot:dialog-content>
          <div class="ibox-content" style="padding: 20px 30px;">
            <div>
              <div class="form-content">
                <template v-if="inspectScheduleType == 0">
                  <div class="form-group row">
                    <span class="notes">注：按添加顺序每日轮班巡检。（只添加一个，每日 巡检都由此人执行）</span>
                    <label
                        class="staff-label"
                    >
                        选择员工
                    </label>
                    <div class="col-sm-9">
                        <div
                            class="row mb-4"
                            v-for="(inspector, index) in everyDayInspector"
                            :key="index"
                        >
                          <div class="col-sm-10">
                              <CSSelect
                                  height="40px"
                                  style="width: 100%"
                                  i-width="36px"
                              >
                                  <select
                                      v-model="
                                          checkedStaffIdList[
                                              index
                                          ]
                                      "
                                      style="padding-left: 10px;"
                                  >
                                      <option
                                          value=""
                                      >
                                      请选择
                                      </option>
                                      <option
                                          v-for="staff in staffOptionsList"
                                              :value="
                                              staff.id
                                          "
                                          :key="
                                              staff.id
                                          "
                                      >
                                          {{
                                              staff.name
                                          }}
                                      </option>
                                  </select>
                              </CSSelect>
                          </div>
                          <div
                              class="col-sm-2"
                              v-if="index > 0"
                          >
                              <svg
                                  class="icon"
                                  aria-hidden="true"
                                  @click="removeEveryDayInspectorItem(index)"
                                  style="line-height:36px; position: absolute; top: 7px;"
                              >
                                  <use xlink:href="#icon-menushanchu"></use>
                              </svg>
                          </div>
                        </div>

                      <div>
                        <button
                            class="btn btn-primary"
                            @click="
                                addEveryDayInspectorItem
                            "
                            style="border-radius: 6px; width: 80px; height: 40px; line-height: 20px;margin-left: 15px;"
                        >
                          添加
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                    <div
                        v-for="(
                              inspectDate, index
                          ) in inspectScheduleData"
                        :key="index"
                        class="margin-bottom"
                    >
                      <template
                          v-if="inspectScheduleType == 1"
                      >
                        <label
                            style="vertical-align: middle; margin-right: 40px; margin-bottom: 0;"
                        >{{
                            weekMapping[inspectDate]
                          }}</label
                        >
                      </template>
                      <template v-else>
                        <label
                            style="vertical-align: middle; margin-right: 40px; margin-bottom: 0;"
                        >{{ inspectDate }}</label
                        >
                      </template>
                      <div style="display: inline-block;">
                        <CSSelect
                            height="40px"
                            style="width: 300px; padding-left: 6px;"
                            i-width="36px"
                        >
                            <select
                                v-model="
                                    checkedStaffIdMapping[
                                        inspectDate
                                    ]
                                "
                            >
                                <option
                                    value=""
                                >
                                    请选择
                                </option>
                                <option
                                    v-for="staff in staffOptionsList"
                                    :value="staff.id"
                                    :key="staff.id"
                                >
                                    {{ staff.name }}
                                </option>
                            </select>
                        </CSSelect>
                      </div>
                    </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </CSDialog>
    </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
const INSPECT_SCHEDULE_TYPE = {
    EVERY_DAY: 0,
    WEEK: 1,
    DATE: 2,
};
import {
    queryDepartmentStaffUrl,
    getInspectPlanScheduleDateUrl,
    setupInspectPlanPerformerUrl,
} from "@/requestUrl";

export default {
    props: {},
    components: { CSSelect, CSDialog },
    data() {
        return {
            weekMapping: {
                1: "周一",
                2: "周二",
                3: "周三",
                4: "周四",
                5: "周五",
                6: "周六",
                7: "周日",
            },
            dialogVisible: false,
            inspectScheduleType: INSPECT_SCHEDULE_TYPE.EVERY_DAY,
            inspectScheduleData: [],
            staffOptionsList: [],
            checkedStaffIdMapping: {},
            everyDayInspector: [''],
            checkedStaffIdList: [''],
        };
    },

    mounted() {
        this.$vc.on(this.$route.path,
            "editInspectorModal",
            "openSetInspectorModal",
            this.getInspectPlanScheduleDate
        );
    },
    methods: {
        removeEveryDayInspectorItem(index) {
            this.checkedStaffIdList.splice(index, 1);
            this.everyDayInspector.splice(index, 1);
        },
        addEveryDayInspectorItem() {
            this.everyDayInspector.push("");
            this.checkedStaffIdList.push('');
        },
        getStaffOptionsList() {
            return this.$fly
                .post(queryDepartmentStaffUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    departmentCode: this.$vc.getCurrentStaffInfo()
                        .departmentCode,
                    jobLevel: "",
                    isOnTheJob: true,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    return res.data;
                });
        },
        async getInspectPlanScheduleDate(planInfo) {
            this.dialogVisible = true;
            this.staffOptionsList = await this.getStaffOptionsList();
            this.orgId = planInfo.orgId;
            this.planId = planInfo.id;
            this.$fly
                .post(getInspectPlanScheduleDateUrl, {
                    planId: planInfo.id,
                    orgId: planInfo.orgId,
                    operatorId: planInfo.operatorId,
                    regionId: this.$vc.getCurrentRegion().communityId,
                })
                .then((res) => {
                    if (res.data) {
                        this.inspectScheduleType = res.data[0]
                            .toString()
                            .includes("-")
                            ? INSPECT_SCHEDULE_TYPE.DATE
                            : INSPECT_SCHEDULE_TYPE.WEEK;
                        this.checkedStaffIdMapping = {};
                        this.inspectScheduleData = res.data.map(item => {
                          this.checkedStaffIdMapping[item] = '';
                          return item;
                        });
                        ;
                        if (planInfo.performersMap) {
                            this.staffOptionsList.forEach((staff) => {
                                for (let key in planInfo.performersMap) {
                                  this.checkedStaffIdMapping[key] = staff.name === planInfo.performersMap[key] ? staff.id : '';
                                }
                            });
                        }
                    } else {
                        this.inspectScheduleType =
                            INSPECT_SCHEDULE_TYPE.EVERY_DAY;

                        this.checkedStaffIdList = Object.keys(
                            planInfo.performersMap
                        );
                        this.everyDayInspector = [""];
                        if (planInfo.performersMap) {
                            for (
                                let i = 1;
                                i < this.checkedStaffIdList.length;
                                i++
                            ) {
                                this.everyDayInspector.push("");
                            }
                        }
                    }
                });
        },
        saveInspector() {
            this.$fly
                .post(setupInspectPlanPerformerUrl, {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    orgId: this.orgId,
                    planId: this.planId,
                    operatorId: this.$vc.getCurrentStaffInfo().id,
                    performers:
                        this.inspectScheduleType ===
                        INSPECT_SCHEDULE_TYPE.EVERY_DAY
                            ? this.$vc.arrToString(this.checkedStaffIdList)
                            : JSON.stringify(this.checkedStaffIdMapping),
                })
                .then((res) => {
                    this.dialogVisible = false;
                    this.$vc.emit(this.$route.path, "addInspectionPlan", "clear", {});
                    this.$vc.emit(this.$route.path,
                        "inspectionPlanManage",
                        "listInspectionPlan",
                        {}
                    );
                });
        },
        dialogClose() {
            this.everyDayInspector = [''];
            this.dialogVisible = false;
            this.checkedStaffIdList = [''];
        }
    },
};
</script>
<style lang="stylus" scoped>
.ibox-content
    font-size 24px
    .col-sm-2.col-form-label
        font-size 24px
    .btn
        font-size 24px
        width 80px
        height 50px
.margin-bottom
    &:not(:last-of-type)
        margin-bottom 23px
.notes {
    font-size 20px
    color #999
    margin-bottom 20px
    display inline-block
}
.staff-label {
    font-size 24px
    width 96px
}
</style>
